import * as React from "react";
import {Link, navigate} from "gatsby";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import { withLayout, LayoutProps, menuItems } from "../components/Layout";
import {
  Button,
  Segment,
  Container,
  Grid,
  Header,
  Icon,
} from "semantic-ui-react";

const IndexPage = (props: LayoutProps) =>
  <div>
    {/* Master head */}
    <Segment vertical inverted textAlign="center" className="masthead">
      <HeaderMenu
        Link={Link} pathname={props.location.pathname} items={menuItems} inverted
      />
      <Container text onClick={() => navigate("/blog/")} className="mainHeader">
        <Header inverted as="h1">Blog['Julian']</Header>
        <Header inverted icon><Icon name={"code"}/></Header>
        <Header inverted as="h2">Mein Notizblog</Header>
      </Container>
      <Container text>
        <p><br/>
          Hier schreibe ich manche Dinge auf, von welchen ich glaube, sie zu teilen oder sie vergessen zu können, ohne darauf verzichten zu müssen sei besser, als dies nicht zu tun.
        </p>
      </Container>
    </Segment>

    {/* About this starter */}
    {/*<Segment vertical className="stripe">
      <Grid stackable verticalAlign="middle" className="container">
        <Grid.Row>
          <Grid.Column width="8">
            <Header>Linux</Header>
            <p>
              Linux ist seit 2006 meine Leidenschaft</p>
            <Header>Dolor sit amet</Header>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Porro laudantium ad, quae, perspiciatis ipsa distinctio.
                </p>
          </Grid.Column>
          <Grid.Column width="6" floated="right">
             TODO replace with a pretty GIF
            <Header>Lorem ipsum</Header>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Porro laudantium ad, quae, perspiciatis ipsa distinctio.
                </p>
            <Header>Dolor sit amet</Header>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Porro laudantium ad, quae, perspiciatis ipsa distinctio.
                </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>*/}

    {/* Key features */}
    <Segment vertical className="stripe alternate feature">
      <Grid columns="3" textAlign="center" relaxed stackable className="container">
        <Grid.Row>
          <Grid.Column>
            <Header icon href="https://github.com/JuStTheDev" target="_blank">
              <Icon name="github"></Icon>
              Mein GitHub Account
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header icon href="https://gitlab.com/JuStTheDev" target="_blank">
              <Icon name="gitlab"></Icon>
              Mein Gitlab Account
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  </div>;

export default withLayout(IndexPage);
